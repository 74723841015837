@import '../../css/variables.scss';
div.heightSpacer{
    height:20px;
}
div.terms-container{
    margin:10px 15px;
    text-align: center;
    span.link-span{
        color:#069;
        text-decoration:underline;
        cursor:pointer;
    }

    .logo-container {
        img{
            width: auto;
            margin-top: 7px;
        }
        img#wasalogo{
            transform: translateY(-10px);
        }

    }
   
    .partner-terms {
        width: 54%;    
        margin: 0 auto;
        font-size:0.8rem;
        span{
            font-size:0.8rem;

        }
    }
}

@media (max-width: 767px) {
    div.terms-container{
        .partner-terms {
            width: 65%;    
        }

        .logo-container {
            img{
                margin-top: 10px;
            }
            img#bankIdLogo{
                height:70px;
            }
            img#wasalogo{
                height:50px;
                transform: translateY(-7px);
            }
    
        }
    }
}


