div.col-container {
    display:flex;
    div.col-float {
        padding:0.25rem;
        vertical-align: top;
        box-sizing: border-box;
    }
    div.col {
        padding:0 2rem 0rem 2rem;
        vertical-align: top;
        box-sizing: border-box;
        &.three {
            width:33%;
            padding:0 2rem 0rem 0rem;
            vertical-align: top;
            box-sizing: border-box;
            &:nth-child(2){
                padding-left:0
            }
        }
        &.two {
            width:50%;
            padding:0 2rem 0rem 0rem;
            vertical-align: top;
            box-sizing: border-box;
        }
        &.one {
            width:100%;
            padding:0 2rem 0rem 0rem;
            vertical-align: top;
            box-sizing: border-box;
        }
        &.full_line {
            width:50%;
            padding:0 0 0rem 0rem;
        }
        &:last-child{
            padding-right:0;
        }
        &:first-child {
            padding-left:0;
        }

    }
    
}

@media (max-width: 767px) {
    div.col-container {
        display:flex;
        flex-direction: column;
        
        div.col.three {
            width:100%;
            padding:0 0rem 0rem 0rem;
            vertical-align: top;
            box-sizing: border-box;
        }
        div.col.two {
            width:100%;
            padding:0 0rem 0rem 0rem;
            vertical-align: top;
            box-sizing: border-box;
        }
    }
   
        
}