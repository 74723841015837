@import '../../css/variables.scss';

.error-container {
  font-family: $font-stack-bold;
  margin: 60px;
  display: inline-block;

  div {
    display: block;
  }

  h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: $lfui-navy;

    span {
      font-family: $font-stack-bold;
      font-size: 1.3em;
      color: $lfui-wine;
    }

  }

  h2 {
    margin-block-start: 0;
    font-size: 1.25em;
    color: $lfui-navy;
  }

  .unicorn {
    display: inline-block;
    width: 195px;
  }
  
  .info-container {
    margin-left: 40px;
  }
  .contact-us {
    color: #00427A;
    font-weight: bold;
  }
}
  