@import './variables.scss';

@font-face {
    font-family: 'IntroCondRegular';
    src: url('../assets/intro-cond-regular.woff2') format('woff2'),
      url('../assets/intro-cond-regular.woff') format('woff'),
      url('../assets/intro-cond-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'IntroCondBold';
    src: url('../assets/intro-cond-bold.woff2') format('woff2'),
      url('../assets/intro-cond-bold.woff') format('woff'),
      url('../assets/intro-cond-bold.ttf') format('truetype'); /* Safari, Android, iOS */
  }
  

body {
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    max-width: 780px;
    color: #222;
}
.clear-both {
    clear: both;
}
p, div, span{
  font-family:$font-stack;
  font-size:0.875rem;
}
h1,h2,h3,h4,h5,h6 {
  font-family: $font-stack-bold;
  color:$lfui-blue;
}
h2 {
  font-size:1rem;
}
h1 {
  font-family: $font-stack-bold;
  font-size: 1.5rem;
  line-height: 24px;
  font-weight: bold;
}
h5{
  margin:18px 0 0 0 ;
  font-family: $font-stack;
  font-size: 0.875rem;
}

input {
  width: 100%;
  font-weight: normal;
  border-radius: 0;
}

input[type=text],
input[type=email] {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 20px;
  font-size: 1rem;
  border: 0;
  outline: none;
  font-family: Arial;
  
}

input:focus {
  border-bottom-color: $lfui-blue;
}

button {
    background-color: $lfui-blue;
    color: #fff;
    border: none;
    float: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: $font-info;
    font-size:1.125rem;
    line-height: 23px;
    height: 45px;
    width: 125px;
    overflow: hidden;
    margin-top: 10px;
    span, div{
      font-size:1.125rem;
    }
    &:disabled {
        cursor: unset;
        color: $lfui-smoke;
        border-color:$lfui-smoke;
        background-color: $lfui-silver;

        &:hover {
            box-shadow: unset;
            background-color: $lfui-gray;
            cursor: unset;
        }
    }
    &:focus{
      outline: 1px solid $lfui-navy;
    
    }
    &:hover {
        background-color: $lfui-navy;
        cursor: pointer;
    }
}

button, div, span {
  font-family:$font-stack;
}

a{
  color:#006fc4;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}


  