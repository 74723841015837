@import '../../css/variables.scss';

div#organization-number-lookup-div {
  margin: 25px 15px;
  p{
    text-align: left;
    margin:3px 0px 0px 0px;
    font-size:0.75rem;
    
    &.creditcheck-disclaimer {
      color:$input-text;
    }

    &.customer-organisation-error {
      color:$validation-error-label;
    }
  }

  h3 {
    margin-bottom: 10px;
  }
  div.button-col{
    margin-left:auto;
  }
  form {
    overflow: hidden;
  }
  button#organization-number-button{
    div.col {
      padding:0 0px 0 0;
    }
    div.col.next_text{
      margin-left:16px;
    }
    div.spinner_space {width:40px;}
  }
  
}