@import '../../css/variables.scss';

div.financing-options {

    border:1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin:15px;
    padding:10px;
    div.flex1{
        flex:1
    }
    h4{
        margin:2px 0px 8px 10px;
    }
    span.financing-options-header{
        display:inline-block;
        margin:0px 0px 4px 0px;
    }
    div.financing-box-readonly{
        padding: 5px 5px 5px 5px;
        border:1px solid #ddd;
        border-radius: 5px;
        color:#005AA0;
        box-sizing: border-box;
        background-color:#fdfdfd;
        text-align: center;
        div.financing-price{
            font-family: $font-stack-bold;
            font-size:18px;
        }
        div.financing-time, div.financing-residual-value{
            font-size:12px;
        }
    
    }


    div.financing-box{
        padding: 5px 5px 5px 5px;
        border:1px solid #ddd;
        border-radius: 5px;
        color:#005AA0;
        box-sizing: border-box;
        background-color:#fdfdfd;
        text-align: center;
        cursor:pointer;
        &:hover{
            background-color:#C9E1F6;

        }
        &.selected{
            border-color:$lfui-blue;
            border-width: 3px;
            margin-top:-2px;
        }
        div.financing-price{
            font-family: $font-stack-bold;
            font-size:18px;
        }
        div.financing-time, div.financing-residual-value{
            font-size:12px;
        }
    
    }
    &.stretch{
        background-color: pink;
        align-items: stretch;
        flex-direction:row;
    }
    div.col-container div.col-float{
        padding:0.5rem;

    }

    .info-text-container{
        margin:0 10px 0 10px;
        h4{
            margin:10px 0 0 0 ;
        }
        p{
            margin:0;
        }
    }
    .financing-options__selected-financing-option-summary{
        margin:5px 0 0 0;
        font-size:12px;
    }
    .insurance{
        margin-top:0px;
        margin-left:5px;
        .financing-options__insurance-checkbox{
            width:auto;
        }
        a.readMore{
            margin-left:6px;
        }
    
    }

}

div.financing-selection-details {
    div.exvat_text{
        text-align: center;  
        padding:0;
    }

    margin-top: 15px;
    span.rental_upplaggningsavgift{
        color:$lfui-blue;
        font-size:0.75rem;
        display: block;
        margin-bottom:20px;
    }

    .centered-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .contract-length {
            font-size:12px;
            color:$lfui-blue;
        }
        .monthly-cost {
            font-family: "IntroCondBold", "Helvetica Neue", Arial, sans-serif;
            font-size: 1.8rem;
            color:$lfui-blue;
        }
        button{
            display: block;
            border: none;
            background: transparent;
            color: $link-color;
            font-size: 0.875rem;
            outline:none;
    }

    }

    .financing-totals {
        -webkit-transition: max-height 0.5s; 
        -moz-transition: max-height 0.5s; 
        -ms-transition: max-height 0.5s; 
        -o-transition: max-height 0.5s; 
        transition: max-height 0.5s;  
        max-height: 130px;
        overflow: hidden;
        &.hide{
            -webkit-transition: max-height 0.5s; 
            -moz-transition: max-height 0.5s; 
            -ms-transition: max-height 0.5s; 
            -o-transition: max-height 0.5s; 
            transition: max-height 0.5s;  
            overflow: hidden;
            // "height: 0" not work with css transitions
            max-height: 0;
            padding:0;
            margin:0;
            border:0;
        }   
        div.exvat_text{
            text-align: center;  
            padding:0;
        }
    h5 {
            font-family: "IntroCondBold", "Helvetica Neue", Arial, sans-serif;
            font-size: 14px;
            color: $lfui-black;
            text-align: center;
        }
        .box {
            border:1px solid #ddd;
            background-color:#fdfdfd;
            border-radius: 5px;
            box-sizing: border-box;
            margin:0px;
            padding:10px;
            max-width: 350px;
            display: flex;
            flex-wrap: wrap;
        div {              
                flex: 100%;  
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;      
                span:nth-child(even) {
                    font-family: "IntroCondBold", "Helvetica Neue", Arial, sans-serif;
                    color:$lfui-blue;
                }
            } 
           
        }
            
            
    }

}
