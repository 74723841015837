@import '../../css/variables.scss';

div#agreement-signee {
    p#sign-disclaimer{
        font-family:$font-info;
        font-size:11px;
        margin:0 0 0 5px;
        color:$lfui-gray;
        line-height: inherit;
    }
    p.agreement-signee__personal-number-error-message{
        font-family:$font-info;
        font-size:11px;
        margin:0 0 0 5px;
        color:$lfui-red;
        line-height: inherit;
    }
}

