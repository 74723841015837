div#disabledWrapperContainer {
    
        // // width: 100px;
        // // height: 100px;
         position: relative;
        // 
        // #content,
        
        div.active#overlay {
            opacity: 0.6;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: white;
            left: 0;
            z-index:10;
        }
       
}
