#change-payment-method {
    float: left;
    img{
        margin-right:5px;
    }
}

#change-payment-method, #change-organization-number {
    img{
        margin-right:5px;
    }
}