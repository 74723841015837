@import '../../css/variables.scss';

.banner_container {
  font-family: $font-stack-bold;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 25px;
  margin: 25px 15px;
  position: relative;

  h4.header {
    margin: 0;
    color:$lfui-blue;
    font-size: 1.17em;
    padding: 0;
    line-height: 1.3;
  }

  
  div.content {
    font-family: $font-stack-bold;
    padding-top: 10px;
    margin-top: 10px;
  }

  h5.sub-title {
    color: $lfui-blue;
    font-size: 1em;
    padding: 0;
    margin: 8px 0 0;
  }

  button {
    width:unset;
    padding: 10px 15px;
    float: right;
    font-size: 1rem;

    &:disabled {
      cursor: unset;
      color:$lfui-smoke;
      border-color:$lfui-smoke;
      &:hover {
        box-shadow: unset;      
      }
  
    }
  }
  .info-text {
    font-family: $font-stack;
    display: inline-block;
    margin-top: 0;
  }
}

