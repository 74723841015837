
@import '../../../css/variables.scss';

div.terms-window{

        &> div, p, span {
            font-size: 16px;
            font-family: "Helvetica Neue",Arial,sans-serif;
        }

        padding:2rem;
        h1,h2 {
            color: $lfui-blue;
        }
      
        h1 {
            line-height: 2.5;
            font-size: 1em !important;
        }
        h2,h3,h4,h5 {
            font-size: 0.875rem !important;
            font-weight: bold;
        }
        p {
            line-height: 1.5;
            font-size: 0.875rem !important;
        }
        ul li {
            line-height: 1.5;
            font-size: 0.875rem !important;
        }


  }