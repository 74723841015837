@import '../../css/variables.scss';

div.agreement-container {
  margin: 25px 15px;
  
  
  h2 {
    margin-bottom:4px;
    }

    button span{
      font-size:inherit
    }

    h4{
      margin:8px 0 0 0;
    }

  h3 {
    margin-bottom: 10px;
  }
  form {
    overflow: hidden;
  }
  
  .signatory-name {
    margin-bottom: 5px;
    margin-top: 5px;
  }
    
  // div.input-container{
  //   &.required {
  //     border-color:$validation-error-label;
  //   }
  // }
  .agreement-signee-header{
      margin-bottom:8px;
      p {
          margin:0;
      }
  }

  .agreement-signee__custom-textarea{
    width: 100%;
    height: 120px;
    padding: 10px;
    box-sizing: border-box;
  }
  .company-ownership{
  }

}